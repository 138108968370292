<template>
  <orderDetail :data="orderDetail" :id="$route.query.id" :type="type" :is-third="true" />
</template>
<script>
import orderDetail from '@/component/orderDetail'
export default {
  components: { orderDetail },
  data() {
    return {
      //基本信息
      orderDetail: {},
      type: 'detail'
    }
  },
  created() {
    if (this.$route.query.type === 'refundDetail') {
      this.type = this.$route.query.type
    }
    // this.getDetail()
  },
  methods: {
    getDetail() {
      let params = {
        params: {
          memberId: this.$route.query.memberId
        }
      }
      this.$api.order
        .order('getOrderDetail', params, this.$route.query.id)
        .then(res => {
          this.orderDetail = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    }
  }
}
</script>

<style lang="less" scoped></style>
